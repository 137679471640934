import * as amplitude from '@amplitude/analytics-browser';

export const initAmplitude = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener('CookieConsent', () => {
      if (window.Cookiebot.consent.statistics) {
        amplitude.init(process.env.GATSBY_AMPLITUDE_API_KEY);
      }
    });
  }
};

export const logEvent = (eventName, eventProperties) => {
  if (typeof window !== 'undefined') {
    amplitude.track(eventName, eventProperties);
  }
};
