/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */

import React from 'react';
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews';
// eslint-disable-next-line import/no-unresolved
import { AuthProvider, BackendProvider } from '@context';
import Cookies from 'js-cookie';
import * as amplitude from '@amplitude/analytics-browser';
import { linkResolver } from '../../src/services/linkResolver';
// Import or global styles
import './src/styles/global.css';

const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
  },
];

export const onClientEntry = () => {
  const cookieConsent = Cookies.get('CookieConsent');

  if (!cookieConsent) {
    return;
  }

  const transformedCookieConsent = cookieConsent
    // Add quotes around unquoted keys: {key: value} => {"key": value}
    .replace(/([{|,])\s*([a-zA-Z0-9]+):/g, '$1"$2":')
    // Convert all single quotes to double quotes: 'value' => "value"
    .replace(/'/g, '"');

  const parsedCookieConsent = JSON.parse(transformedCookieConsent);

  if (parsedCookieConsent?.statistics) {
    amplitude.init(process.env.GATSBY_AMPLITUDE_API_KEY);
  }
};

// Adds a shared React Context for Prismic preview sessions.
export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    <BackendProvider>
      <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>
        {element}
      </PrismicPreviewProvider>
    </BackendProvider>
  </AuthProvider>
);
